import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import address from './address'
import base from './base'
import roles from './roles'
import users from './users'
import categories from './category'
import faq from './faq'
import promo from './promo'
import banner from './banner'
import splash from './splash'
import news from './news'
import settings from './settings'
import station from './station'
import charger from './charger'
import connector from './connector'
import feedback from './feedback'
import feedbackReport from './feedbackReport'
import transaction from './transaction'
import chargersCommand from './chargersCommand'
import dashboard from './dashboard'
import wallet from './wallet'
import payment from './paymentConfiguration'
import withdraw from './withdraw'
import feedbackConfig from './feedbackConfig'
import vehicle from './vehicle'
import coffeeVoucher from './coffeeVoucher'
import rfid from './rfid'
import legal from './legalDocument'
import rfidInvoice from './rfidInvoice'

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    address,
    base,
    roles,
    users,
    categories,
    faq,
    promo,
    banner,
    splash,
    news,
    settings,
    station,
    charger,
    connector,
    feedback,
    feedbackReport,
    transaction,
    chargersCommand,
    dashboard,
    wallet,
    payment,
    withdraw,
    feedbackConfig,
    vehicle,
    coffeeVoucher,
    rfid,
    legal,
    rfidInvoice,
    ...asyncReducers,
  })
  return combinedReducer(state, action)
}

export default rootReducer
