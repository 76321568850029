import { VERSION } from 'constants/api.constant'

const appConfig = {
  apiPrefix: process.env.REACT_APP_BASE_URL + VERSION,
  authenticatedEntryPath: '/dashboard',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/',
  enableMock: false,
}

export default appConfig
