import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchStationApi, fetchStationByIdApi } from 'services/StationService'

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  tableSearch: '',
  mitra: '',
  district: '',
}

const initialForm = {
  mitraId: '',
  name: '',
  address: '',
  districtId: '',
  provinceId: '',
  imageId: '',
  latitude: -6.175392,
  longitude: 106.827153,
  gmapsUrl: 'https://maps.google.com?q=-6.2,106.816666}',
  enableCoffeeFee: false,
}

export const getStationApi = createAsyncThunk('station/getStation', async (param) => {
  const response = await fetchStationApi(param)
  return response.data
})

export const getStationByIdApi = createAsyncThunk('station/getStationById', async (id) => {
  const response = await fetchStationByIdApi(id)
  return response.data
})

const dataSlice = createSlice({
  name: 'station/data',
  initialState: {
    loading: true,
    stationList: [],
    form: initialForm,
    tableData: initialTableData,
    tableSearch: '',
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setTableSearch: (state, action) => {
      state.tableSearch = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    resetForm: (state) => {
      state.form = initialForm
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStationApi.fulfilled, (state, action) => {
        state.loading = false
        state.stationList = action.payload.data.stations
        state.tableData.total = action.payload.data.totalData
      })
      .addCase(getStationApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getStationApi.rejected, (state) => {
        state.loading = false
        state.stationList = []
      })

    builder
      .addCase(getStationByIdApi.fulfilled, (state, action) => {
        state.loading = false
        state.form = action.payload.data
      })
      .addCase(getStationByIdApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getStationByIdApi.rejected, (state) => {
        state.loading = false
        state.form = initialForm
      })
  },
})

export const { setTableData, setTableSearch, setForm, resetForm } = dataSlice.actions

export default dataSlice.reducer
