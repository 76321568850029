export const APP_NAME = process.env.REACT_APP_NAME
export const PERSIST_STORE_NAME = 'admin'
export const REDIRECT_URL_KEY = 'ref'

export const BRAND_NAME = process.env.BRAND_NAME || 'Evoltz Indonesia'
export const BRAND_ID = process.env.BRAND_ID || 'evoltz'
export const SIDEBAR_BACKGROUND = process.env.SIDEBAR_BACKGROUND
export const SIDEBAR_BACKGROUND_COLOR = process.env.SIDEBAR_BACKGROUND_COLOR || '#18181b'

export const OPTIONS_YEAR_INVOICE = process.env.YEAR_INVOICE || 5
